import React, { useEffect, useState } from "react"
import Header from "../components/header.js"
import Footer from "../components/footer.js"
import fashion_1 from "../images/fashion-1.webp"
import fashion_1_mob from "../images/fashion-1-mob.webp"
import Testimonials from "../components/Testimonials.js"
import Fashion_Slider from "../components/Fashion_Slider.js"
import Sucslider from "../components/success_slider.js"
import fashion_icon_1 from "../images/expertise.webp"
import fashion_icon_2 from "../images/photo-editing-tools.webp"
import fashion_icon_3 from "../images/turn-around.webp"
import fashion_icon_4 from "../images/customer-support.webp"
import fashion_2 from "../images/bottom-intro-fashion.webp"
import fashion_2_mob from "../images/bottom-intro-fashion.webp"
import { SuccessStoryData } from "../data/fashionSuccessStoryData.js"
import { TestimonialData } from "../data/fashionTestimonialData.js"
import setKey, { FirstCapital } from "../utils/setKey.js"
import { Trans, useI18next, Link } from "gatsby-plugin-react-i18next"
import { navigate, graphql } from "gatsby"
import fashion_slider_1 from "../images/fashion-slider-1.webp"
import fashion_slider_2 from "../images/skin-retouching-fashion.webp"
import fashion_slider_3 from "../images/contrast-saturation-fashion.webp"

var Fashion_keyword = "Outsource Fashion Photo Editing"

const Fashion = ({ location, ...rest }) => {
  const { languages, changeLanguage } = useI18next()

  const [dataKey, setDataKey] = useState("")
  const [locationKey, setLocationKey] = useState("")
  const [classname, setClassname] = useState("")

  useEffect(() => {
    typeof window !== "undefined" &&
    localStorage.getItem("gatsby-i18next-language") === "de"
      ? setClassname("de")
      : setClassname("")
  })

  useEffect(async () => {
    let data = await setKey(location, "utm_term_fashion", Fashion_keyword)
    if (data.length > 0) {
      setDataKey(data[0])
      setLocationKey(data[1])
    }

    return () => {
      data = null
    }
  }, [])

  useEffect(() => {
    if (locationKey === "Germany") {
      changeLanguage(languages[2])
    } else {
      if (
        rest.pageContext.language === "de" &&
        localStorage.getItem("location") !== "Germany"
      ) {
        localStorage.setItem("gatsby-i18next-language", "en")
        navigate("/outsource-fashion-photo-editing")
      }
    }
  }, [locationKey])

  return (
    <>
      <Header
        metaTitle={
          typeof window !== "undefined" &&
          localStorage.getItem("gatsby-i18next-language") === "de"
            ? "Lagern Sie die Bearbeitung von Modefotos aus, um die Markenattraktivität zu steigern"
            : "Outsource Fashion Photo Editing: Here Art Meets Excellence"
        }
        metaDescription={
          typeof window !== "undefined" &&
          localStorage.getItem("gatsby-i18next-language") === "de"
            ? "Lagern Sie Modefotobearbeitungsdienste aus, um Ihre Modemarke hervorzuheben. Stellen Sie sicher, dass jedes Detail Ihres Produkts für Ihre Kunden glänzt."
            : "Outsource fashion photo editing! We offer top-tier fashion photo editing and photo retouching to give your images a professional finish."
        }
        metakeyword={
          typeof window !== "undefined" &&
          localStorage.getItem("gatsby-i18next-language") === "de"
            ? "Bearbeitung von Modefotos, Retusche von Modefotos, Outsourcing der Fotobearbeitung"
            : "Fashion photo editing, fashion photo retouching, Photo Editing Outsourcing"
        }
        canOnical="https://www.photoeditingoutsourcing.com/outsource-fashion-photo-editing/"
      />

      <div className="home-sec">
        <div className="zindex-div">
          <div className="product-sec fashion-sec">
            <div className="ps-first">
              <div className="container">
                <div className="home-first-sec">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className={`text-box ${
                          classname === "de" ? "text-box-german" : ""
                        }`}
                      >
                        <h1>
                          <Trans>
                            {dataKey && dataKey
                              ? FirstCapital(dataKey)
                              : Fashion_keyword}
                          </Trans>
                        </h1>
                        <p>
                          <Trans>
                          Photo Editing Outsourcing is your go-to provider for high-quality Fashion Photo Editing Services, offering custom photo retouching services.
                          </Trans>
                        </p>
                        <div className="btn-home-sec">
                          <Link to="/contact-us" className="get-started">
                            <Trans>Get started</Trans>
                          </Link>
                          <Link to="/portfolio?tab=1" className="see-port">
                            <Trans>see portfolio </Trans>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="homeimg-box">
                        <img
                          src={fashion_1}
                          alt="fashion-banner-img"
                          className="img-1 desktop-img-1"
                          width="488px"
                          height="578px"
                        ></img>
                        <img
                          src={fashion_1_mob}
                          alt="fashion-banner-img-mob"
                          className="mob-img-1"
                          width="244px"
                          height="289px"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-3-sec">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-12 right-len">
                    <div className="row row-2">
                      <div className="col-md-6 col-lg-3 col-6">
                        <div className="back-color-slider-box">
                          <div className="icon-sec-slider color-1">
                            {" "}
                            <img
                              src={fashion_icon_1}
                              alt="fashion-icon-img-1"
                              width="41px"
                              height="37px"
                            ></img>
                          </div>
                          <h1>
                            <Trans>Expertise in Niche Industries</Trans>
                          </h1>
                          <p>
                            <Trans>
                            Providing Fashion Photo Retouching Services including high fashion and lifestyle brands.
                            </Trans>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3 col-6">
                        <div className="back-color-slider-box">
                          <div className="icon-sec-slider color-2">
                            {" "}
                            <img
                              src={fashion_icon_2}
                              alt="fashion-icon-img-2"
                              width="41px"
                              height="37px"
                            ></img>
                          </div>
                          <h1>
                            <Trans>Latest Photo Editing Tools</Trans>
                          </h1>
                          <p>
                            <Trans>
                            We employ most advanced photo editing software and techniques to deliver high-quality results.
                            </Trans>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3 col-6">
                        <div className="back-color-slider-box">
                          <div className="icon-sec-slider color-1">
                            {" "}
                            <img
                              src={fashion_icon_3}
                              alt="fashion-icon-img-3"
                              width="41px"
                              height="37px"
                            ></img>
                          </div>
                          <h1>
                            <Trans>Quick Turnaround Time</Trans>
                          </h1>
                          <p>
                            <Trans>
                            Quick turnaround time without compromising on the quality of our Fashion Photo Editing Services.
                            </Trans>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3 col-6">
                        <div className="back-color-slider-box">
                          <div className="icon-sec-slider color-2">
                            {" "}
                            <img
                              src={fashion_icon_4}
                              alt="fashion-icon-img-4"
                              width="41px"
                              height="37px"
                            ></img>
                          </div>
                          <h1>
                            <Trans> Customer Support</Trans>
                          </h1>
                          <p>
                            <Trans>
                            24/7 customer support to assist you to ensure a smooth and satisfactory service experience.
                            </Trans>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-12 our-mission">
                    <div className="row  align-items-center">
                      <div className="col-md-12 col-lg-6">
                        <div className="cat-slider-text">
                          <h4>
                            <Trans>PERFECTION</Trans>
                          </h4>
                          <h2>
                            <Trans>
                            High-grade Fashion Photo Editing at Your Finger Tips
                            </Trans>
                          </h2>
                          {typeof window !== "undefined" &&
                          localStorage.getItem("gatsby-i18next-language") ===
                            "de" ? (
                            <p>
                              <Trans>Portrait&fashiondesc</Trans>
                            </p>
                          ) : (
                            <p>
                              When you outsource fashion photo editing, we spotlight the key subject of each photo to heighten its appeal. Our investment in the latest photo editing technology and a team of seasoned professionals ensures every edit work is picture-perfect. We are known in the industry for handling a substantial amount of fashion photo editing in a timely manner without compromising the quality. 
                            </p>
                          )}
                          <p>
                            <Trans>
                            You leverage our more than 15 years of experience when you outsource fashion photo editing to us. Photo Editing Outsourcing has stood out as a trusted outsourcing ally for prominent fashion photographers and photographic studios. Our collaborations extend to photographers, fashion periodicals, portrait photo studios, and apparel brands to fulfill their photo editing necessities.  
                            </Trans>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6">
                        <div className="img-box-about">
                          <img
                            src={fashion_2}
                            alt="fashion-img-2"
                            className="desktop-img-1"
                            width="604px"
                            height="449px"
                          ></img>
                          <img
                            src={fashion_2_mob}
                            alt="fashion-img-2-mob"
                            className="mob-img-1"
                            width="333px"
                            height="250px"
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="abot-2-sec secound-ab">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-6">
                    <h1>
                      <Trans>Quality Fashion Photo Retouching </Trans>
                    </h1>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <p>
                      <Trans>
                      Every fashion photo retouching project is subject to a rigorous quality control process. This ensures that all edits meet and surpass the highest standards in the industry, exceeding your expectations every time.
                      </Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="fashion-slider">
              <div className="cat-slider-text">
                <h4>
                  <Trans>Elegance in Every Pixel</Trans>
                </h4>
                <h2
                  className={`fashion-photo-service ${
                    classname === "de" && "fashion-photo-service-german"
                  }`}
                >
                  <Trans>Outsource Fashion Photo Editing for Perfection</Trans>
                </h2>
                <p className="fashion-photo-service">
                  <Trans>
                  Photo Editing Outsourcing provides expert-level fashion photo editing services meticulously designed to infuse your photographs with an exquisite blend of glamour and elegance.
                  </Trans>
                </p>
              </div>
              <div className="slider-sh">
                <Fashion_Slider />
              </div>

              <div className="fh-slider-mob">
                <div className="fh-slider-box">
                  <div className="fh-slider-mob-img">
                    <img
                      src={fashion_slider_1}
                      alt="fashion-slider-4-img-mob"
                      width="376px"
                      height="378px"
                    ></img>
                    <div className="fh-text">
                      <h2>
                        <Trans>Lighting and Shading</Trans>
                      </h2>
                      <p>
                        <Trans>
                        Fix common lighting and shading imbalances to make the
                        subject more appealing.
                        </Trans>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fh-slider-box">
                  <div className="fh-slider-mob-img">
                    <img
                      src={fashion_slider_2}
                      alt="fashion-slider-5-img-mob"
                      width="376px"
                      height="378px"
                    ></img>
                    <div className="fh-text">
                      <h2>
                        <Trans>Skin Retouching & Airbrushing</Trans>
                      </h2>
                      <p>
                        <Trans>
                        Advanced skin retouching and airbrushing to enhance the
                        subject of the photo.
                        </Trans>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fh-slider-box">
                  <div className="fh-slider-mob-img">
                    <img
                      src={fashion_slider_3}
                      alt="fashion-slider-1-img-mob"
                      width="376px"
                      height="378px"
                    ></img>
                    <div className="fh-text">
                      <h2>
                        <Trans>Contrast and Saturation</Trans>
                      </h2>
                      <p>
                        <Trans>
                        Proper contrast and saturation balancing enhance
                        high-resolution photos.
                        </Trans>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="how-sec">
              <div className="container">
                <h2>
                  <Trans>Photo Editing Outsourcing</Trans>
                </h2>
                {typeof window !== "undefined" &&
                localStorage.getItem("gatsby-i18next-language") === "de" ? (
                  <p className="how-germen-para-text">
                    <Trans>workingdesc</Trans>
                  </p>
                ) : (
                  <p>
                    <Trans>
                    Let our experts transform your fashion photos into stunning visuals. 
                    </Trans>
                  </p>
                )}
              </div>
            </div>
            <div className="category-slider-sec testimonials-sec ecommerce-testi">
              <div className="container">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="cat-slider-text">
                      <h4>
                        <Trans>Word of praise</Trans>
                      </h4>
                      <h3>
                        <Trans>Testimonials</Trans>
                      </h3>
                      <p>
                        <Trans>
                          Don’t just take our word for it. Discover what our
                          customers have to say about us.
                        </Trans>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3"></div>
                </div>
              </div>
              <div className="main-slider">
                <div className="container cate-slider">
                  <Testimonials
                    locationKey={locationKey}
                    TestimonialData={TestimonialData}
                  />
                </div>
              </div>
            </div>
            <div className="Success-sec">
              <div className="container">
                <div className="cat-slider-text">
                  <h4>
                    <Trans>Success</Trans>
                  </h4>
                  <h3>
                    <Trans>Success Stories</Trans>
                  </h3>
                  <p>
                    <Trans>
                      Delivering true value to every customer. Find out how we
                      do it.
                    </Trans>
                  </p>
                </div>
                <div className="main-slider">
                  <div className="container cate-slider">
                    <Sucslider
                      locationKey={locationKey}
                      SuccessStoryData={SuccessStoryData}
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
            {/* <GetStart
              dataKey={FirstCapital(dataKey)}
              pageKeyword={Fashion_keyword}
            /> */}
            <div className="start-sec">
              <div className="container">
                <div className="row  align-items-center">
                  <div className="col-md-12 col-lg-9">
                    <div className="start-text">
                        <h1>
                          <Trans>Outsource Fashion Photo Editing Services</Trans>
                        </h1>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-3">
                    <div className="start-btn">
                      <Link to="/contact-us">
                        <button>
                          <Trans>Get started</Trans>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Fashion

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
